import {Dispatch} from "redux";
import {loginStart} from "./loginReducer";
import {AppState, Credentials} from "../../../types";
import SignInSide, {LoginDispatchProps, LoginStateProps} from "./LoginForm";
import {connect} from "react-redux";


const mapDispatchToProps = (dispatch: Dispatch): LoginDispatchProps => ({
  startLogin: (credentials: Credentials) => dispatch(loginStart(credentials))
});

const mapStateToProps = (state: AppState): LoginStateProps => ({
  loginStatus: state.app ? state.app.login.loginStatus : false
});

export const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(SignInSide);
