import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
// import BackupIcon from '@material-ui/icons/Backup';
import Icon from '@material-ui/core/Icon';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
// import AssignmentIcon from '@material-ui/icons/Assignment';
// import Link from '@material-ui/core/Link';
import { Link } from 'react-router-dom';

export const mainListItems = (
  <div>
    <Link to="/cris/backend/users" style={{textDecoration: 'none', color: "#000000bf"}}>
      <ListItem button>
        <ListItemIcon>
          <PeopleIcon/>
        </ListItemIcon>
        <ListItemText primary="Users"/>
      </ListItem>
    </Link>
    <Link to="/cris/backend/files" style={{textDecoration: 'none', color: "#000000bf"}}>
      <ListItem button>
        <ListItemIcon>
          <Icon>save</Icon>
        </ListItemIcon>
        <ListItemText primary="Files"/>
      </ListItem>
    </Link>
    <Link to="/cris/backend/uploads" style={{textDecoration: 'none', color: "#000000bf"}}>
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon/>
        </ListItemIcon>
        <ListItemText primary="Upload"/>
      </ListItem>
    </Link>
    <Link to="/cris/backend/admin" style={{textDecoration: 'none', color: "#000000bf"}}>
      <ListItem button>
        <ListItemIcon>
          <BarChartIcon/>
        </ListItemIcon>
        <ListItemText primary="Admin"/>
      </ListItem>
    </Link>
    <ListItem button>
      <ListItemIcon>
        <LayersIcon/>
      </ListItemIcon>
      <ListItemText primary="Manage Schedule"/>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <LayersIcon/>
      </ListItemIcon>
      <ListItemText primary="Manage Feeds"/>
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
    {/*<ListSubheader inset>User Settings</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon/>
      </ListItemIcon>
      <ListItemText primary="Profile"/>
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon/>
      </ListItemIcon>
      <ListItemText primary="Settings"/>
    </ListItem>
    <Link to="/login">
      <ListItem button>
        <ListItemIcon>
          <Icon>power-off</Icon>
        </ListItemIcon>
        <ListItemText primary="Logout"/>
      </ListItem>
    </Link>*/}
  </div>
);
