import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './index.scss';
import App from './App';
// import SignInSide from './app/containers/Login/LoginForm';
import Dashboard from './app/containers/Dashboard/Dashboard';
import Admin from './app/containers/Admin/Admin';
import Upload from './app/containers/Upload/Upload';
import Files from './app/containers/Files/Files';
import Schedule from './app/containers/Schedule/Schedule';
import Feeds from './app/containers/Feeds/Feeds';
import qs from 'query-string';
import * as serviceWorker from './serviceWorker';
import {Route, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {ConnectedRouter} from 'connected-react-router';
import {createStore} from './store/store';
import {i18n} from './i18n';
import {LoginContainer} from "./app/containers/Login/LoginContainer";
import Error404 from './app/containers/Error404/Error404';

const history = createBrowserHistory();
const store = createStore(history);
// eslint-disable-next-line no-restricted-globals
const {locale} = qs.parse(location.hash);

i18n(locale as string);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path='/cris/backend/uploads' component={Upload}/>
        <Route path='/cris/backend/files' component={Files}/>
        <Route path='/cris/backend/users' component={Dashboard}/>
        <Route path='/cris/backend/admin' component={Admin}/>
        <Route path='/cris/backend/schedule' component={Schedule}/>
        <Route path='/cris/backend/feeds' component={Feeds}/>
        <Route path='/login' component={LoginContainer}/>
        <Route path='/' component={App}/>
        <Route path = '' component={Error404} />
      </Switch>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
