import * as Redux from 'redux';
import {AppState} from "../types";


export const initState: AppState = {
  app: {
    login: {
      loginStatus: false
    }
  }
};

export type Store = Redux.Store<{}>;
