import * as React from 'react';
import MaterialTable from "material-table";
import { GetData } from '../../services/api';
import Button from '@material-ui/core/Button';
// import Menu from '@material-ui/core/Menu';
import EditIcon from '@material-ui/icons/Edit';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from '@material-ui/core/TextField';
// import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// const payloadPush: any = [];

function MenuButton(props: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const [open, setOpen] = React.useState(false);
  const [openFormModal, setOpenFormModal] = React.useState(false);
  const [payloadPushes, setPayloadPushes] = React.useState([]);

  const handleClickOpen = () => {
    setOpenFormModal(true);
    console.log('a');
  };

  const handleClosed = () => {
    setOpen(false);
    console.log('b');
  };
  const handleCloseEdit = () => {
    setOpenFormModal(true);
    console.log('c');
  }
  const handleClosedModal  = () => {
    console.log(openFormModal);
    console.log('d');
    setOpenFormModal(false);
    setOpen(false);
  }
  const handleCloseDelete = () => {
    setOpenFormModal(true);
  }
  return(
    <div>
    <Button aria-controls="simple-menu" onClick={handleClickOpen}>
    <EditIcon />
    </Button>
    {open ?
    (<Dialog
      open={open}
      onClose={handleClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{" Do you want to Delete?"}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClosed} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClosed} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>) : (<div></div>)}
    {openFormModal ?
    (<Dialog
      open={openFormModal}
      onClose={handleClosedModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{" Do you want to EDIT?"}</DialogTitle>
      <DialogContent>
      <TextField
            disabled
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            value={props.email}
            fullWidth
          />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClosedModal} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClosedModal} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>) : (<div></div>)}
  </div>
  );
}

function FileTables() {
  const [datas, storeDatas] = React.useState([]);
  const payloadPush: any = [];
  React.useEffect(() => {
    function call() {
      if(mounted) {
    GetData('/cris/backend/files?json=true').then(async(res: any) => {
       const tableData = await res.files;
       if(tableData.length > 0) {
        tableData.map((i: any, rwId: any) => {
          const payload = {
            edit: <MenuButton />,
            title: i.title,
            publisher: i.publisher,
            author: i.author,
            };
        payloadPush.push(payload);
        return payloadPush;
        });
      }
      storeDatas(payloadPush);
      // console.log(res.users);
    })
  }
  }
  let mounted = true;
  call();
  return () => {
    mounted = false;
  };
  },[]);
    return (
      <MaterialTable
        title="Files"
        columns={[
          { title: 'Edit', field: 'edit'},
          { title: 'Title', field: 'title' },
          { title: 'Publisher', field: 'publisher' },
          { title: 'author', field: 'author'},

        ]}
        data={datas}
        options={{
          search: true,
          sorting: true,
        }}
      />
    )
}

export default FileTables;
