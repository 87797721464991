import React from 'react';
import logo from './logo.svg';
import './App.scss';
import {useTranslation} from 'react-i18next';

const App: React.FC = () => {
  const [t] = useTranslation();
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo} className='App-logo' alt='logo' />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className='App-link'
          href='https://reactjs.org'
          target='_blank'
          rel='noopener noreferrer'
        >
          Learn React with {t('example.name')}, {t('example.template_key', {number: 10})}
        </a>
      </header>
    </div>
  );
}

export default App;
