import {Request} from './restApi';
import {Credentials} from "../../types";
const Routes = {
  login: () => `${process.env.REACT_APP_API_SERVER}/cris/user/authenticate?json=true`,
  getUser: () => `${process.env.REACT_APP_API_SERVER}/cris/backend/users?json=true`
}

export const loginRequest = async (credentials: Credentials) => {
  return await Request.post(Routes.login(), credentials);
};

export const getUsers = async () => {
  return await Request.get(Routes.getUser());
};
