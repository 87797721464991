
import React, {useState} from 'react';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import Title from './Title';
// import Orders from './Table';
import {Header} from "../../common/Header";
import {useTranslation} from "react-i18next";
import {Copyright} from "../../common/Copyright";
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
// import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/core/styles';
// import AddIcon from '@material-ui/icons/Add';
import DataTable from '../DataTable/DataTable';
import { PostData } from '../../services/api';
import Spinner from '../../common/Spinner';
import SnackBar from '../../common/SnackBar';
import moment from 'moment';
// import { monitorEventLoopDelay } from 'perf_hooks';
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  buttons: {
    margin: theme.spacing(1),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(3),
    width: 250,
  },
  buttones: {
    marginLeft: theme.spacing(7),
    marginTop: theme.spacing(3),
    width: 400,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

interface State {
  firstName: string;
  password: string;
  dates: string;
  lastName: string;
  multiline: string;
  currency: string;
  email: string;
}


export default function Dashboard() {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [formSubmitType, setFormSubmitType] = React.useState(true);
  const [toaster, setToaster] = useState(false);
  const [toasterType, setToasterType] = useState('warning');
  const [toasterMessage, setToasterMessage] = useState('');
  const [t] = useTranslation();
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [values, setValues] = React.useState<State>({
    firstName: '',
    password: '',
    dates: moment().format('YYYY-MM-DD'),
    email: '',
    lastName: '',
    multiline: '',
    currency: '',
  });
  const submit = (e: any) => {
  e.preventDefault();
  setToaster(false);
  if(values.firstName === '') {
    setToaster(true);
    setToasterType('error');
    setToasterMessage('First name can not be blank');
    setTimeout(() => {
      setToaster(false);
    },3000)
  } else if(values.lastName === '') {
    setToaster(true);
    setToasterType('error');
    setToasterMessage('Last name can not be blank');
    setTimeout(() => {
      setToaster(false);
    },3000)
  } else if(values.password === '') {
    setToaster(true);
    setToasterType('error');
    setToasterMessage('Password can not be blank');
    setTimeout(() => {
      setToaster(false);
    },3000)
  } else {
    setFormSubmitType(false);
    const payload = {
      active: '1',
      role: 'user',
      subscription_type: 'demand_30_y',
      subscription_agreement: '1',
      terms_condition: '1',
      first_name: values.firstName,
      last_name: values.lastName,
      subscription_end: values.dates,
      email: values.email,
      password: values.password
    }
    PostData('cris/backend/users', payload).then((res) => {
      console.log(res);
    })
    // console.log(payload)
  }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (name: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={t('dashboard.title')}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container direction="row" justify="flex-end" alignItems="flex-end">
            <Grid item>
            <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttons}
                    onClick={handleClickOpen}
                    >
                    CREATE USER
                  </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              {/* <Paper className={classes.paper}>
                <React.Suspense  fallback={<div>pending</div>}>
                <DataTable />
                </React.Suspense>
              </Paper> */}
               <React.Suspense  fallback={<div>pending</div>}>
                <DataTable />
                </React.Suspense>
            </Grid>
                        {/* Chart */}
            {/* <Grid item>
              <Paper className={fixedHeightPaper}>

              </Paper>
            </Grid> */}
          </Grid>
        </Container>
        <Copyright />
      </main>
      {open ? (<div>
        {formSubmitType ? (<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create User</DialogTitle>
        <DialogContent>
        <form onSubmit={(e: any) => submit(e)} autoComplete="off">
        <Grid container direction="row" justify="flex-end" alignItems="flex-end">
            <Grid item xs={12}>
            <TextField
                    id="standard-name"
                    label={t('dashboard.userForm.firstNameLabel')}
                    className={classes.textField}
                    value={values.firstName}
                    onChange={handleChange('firstName')}
                    margin="normal"
                    fullWidth
                  />
            </Grid>
            <Grid item xs={12}>
            <TextField
                    id="standard-uncontrolled"
                    label={t('dashboard.userForm.lastNameLabel')}
                    value={values.lastName}
                    onChange={handleChange('lastName')}
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                  />
            </Grid>
            <Grid item xs={12}>
            <TextField
                    required
                    id="standard-required"
                    label={t('dashboard.userForm.emailLabel')}
                    type="email"
                    value={values.email}
                    onChange={handleChange('email')}
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                  />
            </Grid>
            <Grid item xs={12}>
            <TextField
                    id="standard-error"
                    label={t('dashboard.userForm.passwordLabel')}
                    type="password"
                    value={values.password}
                    onChange={handleChange('password')}
                    autoComplete="current-password"
                    className={classes.textField}
                    margin="normal"
                    fullWidth
                  />
            </Grid>
            <Grid item xs={12}>
            <TextField
                    id="date"
                    label={t('dashboard.userForm.subscriptionLabel')}
                    type="date"
                    value={values.dates}
                    onChange={handleChange('dates')}
                    fullWidth
                    className={classes.textField}
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
            </Grid>
            <Grid item xs={12}>
            <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.buttones}
                    >
                    {t('dashboard.userForm.createButton')}
                  </Button>
            </Grid>
        </Grid>
        </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>): (
        <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogContent><Spinner /></DialogContent>
      </Dialog>) }</div>) : (<div></div>)}
      {toaster ? (<SnackBar status={toaster} type={toasterType} message={toasterMessage}/>) :
    (<div></div>) }
    </div>
  );
}
