import {createAction, handleActions, Action} from "redux-actions";
import {DashboardState} from "../../../types";

export const DEMO_ACTION = 'DEMO_ACTION';
export const demoAction = createAction(DEMO_ACTION);
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';

export const dashboardReducer = handleActions<DashboardState, object>({
  [DEMO_ACTION]: (state: DashboardState, {payload}: Action<object>) => {
    return {
      ...state,
      userDetails: payload
    }
  }
}, {
  userDetails: [{}]
});
