
import {ForkEffect, takeEvery, select, takeLatest} from 'redux-saga/effects';
import {loginSaga} from "../app/containers/Login/loginSaga";
import {LOGIN_START} from "../app/containers/Login/loginReducer";


/**
 * Function to demonstrate logging on log.log using sagas
 * @param action
 */

function* logger(action: any) {
  console.log('Action:', action, 'state after:', yield select());
}

/*
We could add a wrapper to ensure type matching: (maybe also possible by adding type declaration

const takeLatestT = <PayLoad>(
  creator: (payload: PayLoad) => Action<PayLoad>, saga: (action: Action<PayLoad>) => void
) => takeLatest(creator, saga);
*/

export default function* rootSaga(): IterableIterator<ForkEffect> {
  yield takeEvery('*', logger);
  yield takeLatest(LOGIN_START, loginSaga);
}
