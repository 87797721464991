
import React from 'react';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Header} from "../../common/Header";
import {useTranslation} from "react-i18next";
import TextField from '@material-ui/core/TextField';

const drawerWidth = 240;


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
  appBarSpacer: theme.mixins.toolbar,
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  }
}));


interface State {
  firstName: string;
  lastName: string;
  multiline: string;
  currency: string;
  mediaType: string;
}

export default function Upload () {

  const [t] = useTranslation();

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [values, setValues] = React.useState<State>({
    firstName: 'Prashant',
    lastName: 'Sudeep',
    multiline: 'Controlled',
    currency: 'EUR',
    mediaType: ''
  });

  const handleChange = (name: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={t('upload.title')}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
            {/* Schedule */}
            <Grid item xs={12}>
              <Paper>
                <form className={classes.container} noValidate autoComplete="off">
                  <TextField
                    id="standard-name"
                    label="Enter Title"
                    className={classes.textField}
                    onChange={handleChange('firstName')}
                    margin="normal"
                  />
                  <TextField
                    id="standard-uncontrolled"
                    label="Enter Publisher"
                    onChange={handleChange('lastName')}
                    className={classes.textField}
                    margin="normal"
                  />
                  <TextField
                    required
                    id="standard-required"
                    label="Enter Classification"
                    className={classes.textField}
                    margin="normal"
                  />
                  <TextField
                    id="standard-name"
                    label="Enter Author"
                    className={classes.textField}
                    onChange={handleChange('firstName')}
                    margin="normal"
                  />
                  <TextField
                    id="standard-uncontrolled"
                    label="Enter Grade level"
                    onChange={handleChange('lastName')}
                    className={classes.textField}
                    margin="normal"
                  />
                  <TextField
                    required
                    id="standard-required"
                    label="Enter Volunteer voice"
                    className={classes.textField}
                    margin="normal"
                  />
                  <FormControl className={classes.textField}>
                    <InputLabel htmlFor="age-simple">Media Type</InputLabel>
                    <Select
                      value={values.mediaType}
                      inputProps={{
                        name: 'mediaType',
                        id: 'age-simple',
                      }}
                    >
                      <MenuItem value={'book'}>Book</MenuItem>
                      <MenuItem value={'magzine'}>Magazine</MenuItem>
                      <MenuItem value={'poem'}>Poems</MenuItem>
                      <MenuItem value={'article'}>Article</MenuItem>
                      <MenuItem value={'letter'}>Letter</MenuItem>
                      <MenuItem value={'excerpt'}>Excerpt</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="standard-uncontrolled"
                    label="Enter ISBN"
                    onChange={handleChange('lastName')}
                    className={classes.textField}
                    margin="normal"
                  />
                  <TextField
                    required
                    id="standard-required"
                    label="Enter File Link"
                    className={classes.textField}
                    margin="normal"
                  />
                  <TextField
                    id="standard-error"
                    label="Enter School"
                    className={classes.textField}
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.textField}
                    >
                    Upload
                  </Button>
                </form>
              </Paper>
            </Grid>
          </Grid>
         </Container>
      </main>
    </div>
  );
}
