import axios from 'axios';

const BASEURLAPI = 'https://cris.medimojo.co';

// const fetchUser = (userId: string) => {
//   // `axios` function returns promise, you can use any ajax lib, which can
//   // return promise, or wrap in promise ajax call
//   return axios.get('/api/user/' + userId);
// };

export const GetData = async(apiUrl: any) => {
  //  checkLogin();
    const fetchData = await  axios.get(`${BASEURLAPI}${apiUrl}`);
    return fetchData.data;
  }

export const PostData = async(apiUrl: string, postParam: any) => {
    const  returnData = await axios.post(`${BASEURLAPI}${apiUrl}`, postParam, {
      headers: {
          'Content-Type': 'application/json',
      }
  });
    return returnData.data;

   }

// module.exports = {
//   fetchUser,
// };
