import {HttpStatusCode} from "./HttpStatusCode";

export class FetchException extends Error {
  constructor(message: string, public response: any, public url: string, public requestInit?: any) {
    super(message);
  }
}

// type guard
export const isFetchException = (err: FetchException | Error): err is FetchException =>
  (err as FetchException).response !== undefined;

// tslint:disable-next-line:no-string-based-set-timeout
export const sleep = async (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const handleStatus = (url: string, init?: RequestInit) => (response: Response) => {
  if (response.status >= HttpStatusCode.OK && response.status < HttpStatusCode.MULTIPLE_CHOICES) {
    return response;
  }

  throw new FetchException(response.status.toString(), response, url, init);
};

export const raisingFetch = async (url: string, init?: RequestInit): Promise<Response> =>
  fetch(url, init).then(handleStatus(url, init)).then(res => res);

/**
 * Expected usage is always Request.function
 */
export const Request = {
  post: async <T>(url: string, body: T) => {
    const requestInit: RequestInit = {
      method: 'POST',
      //credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };

    return raisingFetch(url, requestInit);
  },
  get: async (url: string, credentials: RequestCredentials = 'include') => {
    const requestInit: RequestInit = {
      method: 'GET',
      credentials
    };

    return raisingFetch(url, requestInit);
  },
  put: async <T>(url: string, body: T) => {
    const requestInit: RequestInit = {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(body)
    };

    return raisingFetch(url, requestInit);
  },
  delete: async (url: string) => {
    const requestInit: RequestInit = {
      method: 'DELETE',
      credentials: 'include'
    };

    return raisingFetch(url, requestInit);
  }
};

export const postRetry = async <T> (
  payload: T, url: string, maxRetries: number, nRetry = 0
): Promise<number> => {
  try {
    const response = await Request.post(url, payload);
    return response.status;
  } catch (err) {
    if (((err.response && err.response.status >= HttpStatusCode.INTERNAL_SERVER_ERROR) ||
      err.message === 'Failed to fetch') && nRetry < maxRetries) {
      await sleep((nRetry + 1) * 1000);
      return postRetry(payload, url, maxRetries, nRetry + 1);
    } else {
      throw err;
    }
  }
};

/**
 * Using BmResponse name as Response is standard type.
 * Expected usage is always BmResponse.function
 */
export const Response = {
  parseJSON: async <T>(response: Response): Promise<T> => {
    return response.json();
  },
  getBlob: async (response: Response) => {
    return response.blob();
  }
};
