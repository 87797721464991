import {put, call} from "redux-saga/effects";
import {loginRequest} from "../../services/apiCalls";
import {Action} from "redux-actions";
import {loginStart, loginSuccess} from "./loginReducer";
import {Credentials} from "../../../types";

export function* loginSaga({payload}: Action<Credentials>) {
  //yield put(loginStart(payload));
  console.log(payload);
  try {
    const response = yield call(loginRequest, payload);
    console.log(response);
    yield put(loginSuccess({
      type: 'LOGIN_SUCCESS1',
      data: response,
    }));
  } catch(e) {
    yield put(loginSuccess({type: "LOGIN_FAILED", message: e.message}));
  }
}
