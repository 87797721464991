import * as React from 'react';
import MaterialTable from "material-table";
import { GetData } from '../../services/api';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


function MenuButton(props: any) {
  console.log(props);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const [open, setOpen] = React.useState(false);
  const [openFormModal, setOpenFormModal] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    console.log('a');
  };

  const handleClosed = () => {
    setOpen(false);
    console.log('b');
  };
  const handleCloseEdit = () => {
    setOpenFormModal(true);
    console.log('c');
  }
  const handleClosedModal  = () => {
    console.log(openFormModal);
    console.log('d');
    setOpenFormModal(false);
    setOpen(false);
  }
  const handleCloseDelete = () => {
    setOpenFormModal(true);
  }
  return(
    <div>
    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
    <MoreVertIcon />
    </Button>
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleCloseEdit}>EDIT</MenuItem>
      <MenuItem onClick={handleCloseDelete}>DELETE</MenuItem>
    </Menu>
    {open ?
    (<Dialog
      open={open}
      onClose={handleClosed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{" Do you want to Delete?"}</DialogTitle>
      <DialogActions>
        <Button onClick={handleClosed} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClosed} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>) : (<div></div>)}
    {openFormModal ?
    (<Dialog
      open={openFormModal}
      onClose={handleClosedModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{" Do you want to EDIT?"}</DialogTitle>
      <DialogContent>
      <TextField
            disabled
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            value={props.data.email}
            fullWidth
          />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClosedModal} color="primary">
          Cancel
        </Button>
        <Button onClick={handleClosedModal} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>) : (<div></div>)}
  </div>
  );
}

function DataTable() {
  const [datas, storeDatas] = React.useState([]);
  React.useEffect(() => {
    const payloadPush: any = [];
    GetData('/backend/users?json=true').then(async(res: any) => {
       const tableData = await res.users;
         if(tableData.length > 0) {
          tableData.map((i: any, rwId: any) => {
    if(i.active === 1) {
    const payload = {
    userId: i.id,
    email: i.email,
    active: 'true',
    subscription: i.subscription_end,
    name: `${i.first_name}${i.last_name}`,
    action: <MenuButton data={i} />
    };
    payloadPush.push(payload);
  } else {
    const payload = {
      userId: i.id,
      email: i.email,
      active: 'false',
      subscription: i.subscription_end,
      name: `${i.first_name}${i.last_name}`,
      action: <MenuButton data={i}/>
      };
      payloadPush.push(payload);
  }
  return payloadPush;
  });
}
      storeDatas(payloadPush);
      // console.log(res.users);
    })
  },[]);
    return (
      <MaterialTable
        title="Users"
        columns={[
          { title: 'User Id', field: 'userId' },
          { title: 'User Email', field: 'email' },
          { title: 'Active', field: 'active'},
          { title: 'Subscription End', field: 'subscription'},
          { title: 'Name', field: 'name'},
          { title: 'Action', field: 'action'}

        ]}
        data={datas}
        options={{
          search: true
        }}
      />
    )
}

export default DataTable;
