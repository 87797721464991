import {Action, createAction, handleActions} from "redux-actions";
import {Credentials, LoginState} from "../../../types";

export const LOGIN_START = 'LOGIN_START';
export const loginStart = createAction<Credentials>(LOGIN_START);
export const LOGIN_SUCCESS1 = 'LOGIN_SUCCESS';
export const LOGIN_FAILED =  'LOGIN_FAILED';
export const loginSuccess = createAction(LOGIN_SUCCESS1);
export const loginFailed = createAction(LOGIN_FAILED);

export const loginReducer1 = handleActions<LoginState, boolean>({
  [LOGIN_SUCCESS1]: (state: LoginState, {payload}: Action<boolean>) => {
    return {
      loginStatus: payload
    }
  }
}, {
  loginStatus: false
});
